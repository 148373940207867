<script setup>
  import {
    ref
  } from 'vue'

  const show = ref(false)

  const {
    privacyPolicyPath
  } = window.app.common

  if (document.cookie.indexOf("accepted") < 0) {
    show.value = true
    document.cookie = 'accepted=t; max-age=157680000'
  }

  const handleOk = () => show.value = false
</script>
<template>
  <div v-if="show" id="header-cookie" class="l-header__cookie" @click="() => show = false" v-cloak>
    <div class="container">
      <p>We use cookies to give you the best possible experience on our site and to analyse traffic. By continuing
        browsing this website, you agree to our use of cookies. View our <a :href="privacyPolicyPath">Privacy Policy</a>&nbsp;for more information.<a class="clink" href="#" id="accept-cookie" @click="handleOk"> OK</a></p>
    </div>
  </div>
</template>
