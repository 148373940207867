<script setup>
  import {
    ref
  } from 'vue'

  import { useI18n } from '@shared/i18n.js'
  import { useAuthentication } from '@composables/useAuthentication.js'

  const opening = ref(false)

  const { vt, t } = useI18n('shared.header.js')

  const {
    bookmarksPath,
    deleteAccountPath,
    logoutPath,
  } = window.app.common

  /**
   * メニューの開閉
   */
  const handleClickMenu = () => opening.value = !opening.value

  /**
   * storageの情報を削除してからログアウトの遷移をさせる
   */
  const handleLogout = () => {
    const { signedOutToStorage } = useAuthentication()
    signedOutToStorage()
    window.location.href = logoutPath
  }
</script>

<template>
  <div class="sub_list nav-item d-none d-lg-block logIn my-account">
    <a class="nav__acc" @click="handleClickMenu">{{ vt('myaccount') }}</a>
    <ul :class="{ open: opening }" class="megaMenu">
      <li><a @click="handleClickMenu">{{ vt('myaccount') }}</a></li>
      <li><a :href="bookmarksPath">{{ vt('favorite') }}</a></li>
      <li><a :href="deleteAccountPath">{{ vt('delete_account') }}</a></li>
      <li><a @click="handleLogout">{{ t('c.logout') }}</a></li>
    </ul>
  </div>
  <ul class="sub_list d-lg-none">
    <li class="nav-item logIn">
      <a @click="handleLogout">{{ t('c.logout') }}</a>
    </li>
  </ul>
</template>
