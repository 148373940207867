<script setup>
  import {
    onMounted,
    ref,
    watch
  } from 'vue'

  import { useI18n } from '@shared/i18n.js'

  const locale = ref(document.querySelector('html').getAttribute('lang'))
  const locales = { en: 'English', 'zh-CN': '简体中文', 'zh-TW': '繁體中文', th: 'ไทย' }

  const { t } = useI18n()

  onMounted(() => {
    $(".c-select__language select").SumoSelect()
  })

  watch(locale, (val) => location.href = window.app.common.localesPath[val])
</script>

<template>
  <div>
    <label class="c-select__language">
      <select v-model="locale" class="" tabindex="-1">
        <option class="select_placeholder" disabled="true" selected="true" :value="locale">{{ t('shared.header.language') }}</option>
        <option v-for="(text, value) in locales" :key="`locale-${value}`" :class="{ cr: value == locale }" :value="value">{{ text }}</option>
      </select>
    </label>
  </div>
</template>
