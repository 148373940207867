import CookieAcceptance from './CookieAcceptance.vue'
import LanguageSelector from './LanguageSelector.vue'
import SignIn from './SignIn.vue'
import UserMenu from './UserMenu.vue'

import { useAuthentication } from '@composables/useAuthentication.js'
import { pageTop } from '@shared/util.js'

export default {
  components: {
    CookieAcceptance,
    LanguageSelector,
    SignIn,
    UserMenu,
  },

  setup() {
    const { signedIn } = useAuthentication()

    return {
      signedIn
    }
  },

  data() {
    return {
      openingSpMenu: false
    }
  },

  mounted() {
    initializeSubMenus()
    watchPageToTop()
    smoothScroll()
  },
}

const initializeSubMenus = () => {
  $('.nav-item a, .nav-item p').on("click", function() {
    if (window.innerWidth <= 992) {
      $(this).next('.sub-menus').slideToggle(500)
      $(this).toggleClass('is-open')
    }
  })
}

const smoothScroll = () => {
  // スクロール無しリスト
  setTimeout(() => {
    const noList = '.more_open, .more_close, .select_price .nav-link'
    $('a[href^="#"]').not(noList).click(function(){
      const headerHightPc = 60
      const headerHightSp = 60
      const speed = 400
      const href= $(this).attr("href")
      const target = $(href == "#" || href == "" ? 'html' : href)
      const positionPc = target.offset().top-headerHightPc
      const positionSp = target.offset().top-headerHightSp
      if (window.innerWidth >= 768) {
        $('html, body').animate({scrollTop:positionPc}, speed, "swing")
      } else {
        $('html, body').animate({ scrollTop: positionSp }, speed, "swing")
      }
      return false;
    })
  }, 1000)
}

const watchPageToTop = () => {
  const $button = $('#pagetop')
  $button.on('click', () => pageTop())
  if(window.innerWidth >= 768) {
    $button.hide()
    $(window).scroll(() => $(window).scrollTop() > 800 ? $button.fadeIn(500) : $button.fadeOut(500))
  }
}
