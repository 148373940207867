<script setup>
  import { useI18n } from '@shared/i18n.js'
  import { generateUrl } from '@shared/util.js'

  const { t } = useI18n()

  const loginPath = generateUrl('login', '')
  const signupPath = generateUrl('signup', '')
</script>
<template>
  <ul class="sub_list">
    <li class="nav-item logIn">
      <a :href='loginPath' class='nav-link'>{{ t('c.login') }}</a>
    </li>
    <li class="nav-item logOut">
      <a :href='signupPath' class='nav-link'>{{ t('c.sign_up') }}</a>
    </li>
  </ul>
</template>
