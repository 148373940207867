import { createApp } from 'vue/dist/vue.esm-bundler'
import { createI18n } from '@shared/i18n'
import locales from '../i18n/locales.json'

import Header from '@components/header/App.js'

import '../css/application.css'

const i18n = createI18n(locales)

document.addEventListener('DOMContentLoaded', () => {
  createApp(Header).use(i18n).mount('header')
})
